<template>
  <div class="team-activity">
    <router-link to="seaIndex" class="home">
      <yd-icon name="home" size=".5rem" color="#fff"></yd-icon>
    </router-link>
    <div class="ceremony" :style="{backgroundImage:'url(' + (backImg ? backImg : require('@/assets/haiyuansu/teamBack.jpg')) + ')'}">
      <div class="process">
        <div class="ceremony-content">
          <tab ref="tab" :callback="tabCallBack" border-color="#fff" bg-color="#F6432F" color="#fff">
            <yd-tab-panel tabkey="0" label="组队奖励">
                <div ref="lotterRef" class="team-border">
                  <div v-show="!no_activity || datailList.length >= 1" class="team-prize" v-for="(item, index) in datailList" :key="item.id">
                    <div class="border-icon">
                      礼包{{index + 1}}
                    </div>
                    <div class="img">
                      <img :src="item.item_img" alt="">
                    </div>
                    <div class="claim">
                      <p class="prize-name">{{item.item_name}}</p>
                      <p class="clain-con">队伍累计需要<span class="number">{{item.team_goal_num}}</span>人</p>
                      <div v-if="item.team_user" class="team-user">
                        <p>{{item.team_user.user_num}}/{{item.team_goal_num}}</p>
                        <div class="schedule">
                          <div class="actually-sch" :style="{width: (item.team_user.user_num/item.team_goal_num) * 100 + '%'}"></div>
                        </div>
                      </div>
                    </div>
                    <div class="btn" :class="{'end': item.team_user && item.team_user.is_grant}">
                      <div v-if="item.team_user && !item.team_user.is_grant" class="btn-layout" :class="{'failure': !item.team_user.is_enough}" @click="item.team_user.is_enough ? receiveAwardTeam(item.team_user.id) : ''">{{item.team_user.is_enough ? '领取奖励' : '未完成'}}</div>
                      <div v-if="item.team_user && item.team_user.is_grant" class="team-end">
                        <img src="~@/assets/haiyuansu/already.png" alt="">
                        <p class="detail-team" @click="receiveAwardTeam(item.team_user.id)">查看领取详情</p>
                      </div>
                      <div v-if="!item.team_user" class="btn-layout" :class="{'disabled': visitStatus || Number(item.item_num) <= 0 || btnStatus}" @click.stop="visitStatus || Number(item.item_num) <= 0 || btnStatus ? '' : joinTeam(item.id, item.lottery_id)">参与组队</div>
                    </div>
                  </div>
                  <div class="activity-tip" v-show="no_activity || datailList.length < 1">
                    {{ no_activity ? activityTip : '暂无礼包'}}
                  </div>
                </div>
            </yd-tab-panel>
            <yd-tab-panel tabkey="1" label="我的队伍">
              <div v-show="peopleList.length >= 1"  class="team-border">
                <div class="my-team">
                  <p class="team-num">您的队伍已有{{user_num}}人</p>
                  <p class="eqit" @click="exitsTeamTip">退出队伍</p>
                </div>
                <div ref="teamRef" class="detail">
                  <div class="detail-title">
                    <p class="kehu-num common">客户名</p>
                    <p class="join-time common">加入时间</p>
                  </div>
                  <div class="detail-title" v-for="(item, ind) in peopleList" :key="ind">
                    <p class="mobile common">{{item.nickname}}</p>
                    <p class="time common">{{item.create_time}}</p>
                  </div>
                </div>
              </div>
              <div v-show="peopleList.length < 1" class="no-team">
                暂无队伍信息
              </div>
            </yd-tab-panel>
            <yd-tab-panel tabkey="2" label="活动规则">
              <div v-show="!no_activity" class="active-rule team-border">
                <div class="active-time">
                  <p class="time-txt">活动时间</p>
                  <p class="time-num">{{ startTime | formatDateTime }} 至 {{endTime | formatDateTime}}</p>
                </div>
                <!-- <div class="active-time">
                  <p class="time-txt">参与规则</p>
                  <p class="time-detail">指定用户可参与</p>
                </div> -->
                <div class="active-time">
                  <p class="time-txt">活动规则</p>
                  <div ref="ruleRef" class="rule-detail time-detail">
                    <p class="rule-txt" v-html="rule"></p>
                  </div>
                </div>
              </div>
              <div  v-show="no_activity"  class="activity-tip">
                {{activityTip}}
              </div>
            </yd-tab-panel>
        </tab>
        </div>

      </div>

      <div class="visited-btn" :class="{'no-click': !visitStatus}" @click.stop="shareGuide">
        <div class="btn-con">
          <img src="@/assets/haiyuansu/forward.png" alt="">
          <span>立即邀请</span>
        </div>
      </div>

      <alert-tip :type="type" :show-alert="show_tip" :alert-text="tip" @changeShowAlert="changeShow"></alert-tip>

      <div v-if="isShareStatus" class="share-user" @click.self="appearVisit">
        <div class="visit-user">
          <div class="visit-content">
            <p class="visit-title">组队邀请</p>
            <p class="visit-title">{{visitName}}邀请您一起参与组队，赢取豪礼！</p>
            <p class="visit-title">队伍成员</p>
            <div class="number-list">
              <div class="detail-title">
                <p class="kehu-num common">客户名</p>
                <p class="join-time common">加入时间</p>
              </div>
              <div class="detail-title" v-for="(item, ind) in vPeopleList" :key="ind">
                <p class="mobile common">{{item.nickname}}</p>
                <p class="time common">{{item.create_time}}</p>
              </div>
            </div>
            <div class="join-team" @click="enterTeam">
              马上进组赢好礼
            </div>
            <div class="no-join" @click="appearVisit">
              我再想想
            </div>
          </div>
        </div>
      </div>
      <!--引导分享-->
      <div v-if="show_guide" class="guide-bg" @click="show_guide=false"></div>
      <div class="guide-box" v-if="show_guide">
        <div class="guide-top"></div>
        <div class="flex-center" @click="show_guide=false">
          <img class="guide-btn" src="@/assets/button_iknow@3x.png" alt>
        </div>
      </div>
    </div>
    <!-- <div v-show="no_activity"  class="lucky-wheel df fdc alc">
      <div class="empty-box df fdc alc">
        <img :src="emp_img" alt="emp_img" />
        活动未开始
      </div>

      <div v-if="loginDialog" class="login-entrys" @click.self="appear">
        <login-dialog @submit="submit(arguments)" @cancel="cancel"></login-dialog>
      </div>
    </div> -->
    <div v-if="loginDialog" class="login-entrys" @click.self="appear">
      <login-dialog @submit="submit(arguments)" @cancel="cancel"></login-dialog>
    </div>
  </div>
</template>

<script>
import Tab from '@/components/scrollNav/tab.vue'
import { mapMutations, mapState } from "vuex";
import {debounce} from "@/utils/debounce";
import md5 from "js-md5";
import {login3, getColor} from "@/services";
import {toolTip, inputMixins} from "@/mixins";
import AlertTip from "@/components/common/AlertTip";
import LoginDialog from './loginDialog.vue'
import { baseUrl } from "@/utils/env";
import { getActivityDetail, createTeam, getMyTeamInfo, enterTeam, exitsTeam, getTeamMember } from "@/services/my";

    export default {
      name: 'ceremony',
      components: {
        AlertTip,
        LoginDialog,
        Tab
      },
        data() {
          return {
            datailList: [],
            peopleList: [],  //  组队成员
            vPeopleList: [],  //  邀请过来的队伍列表
            backImg: '',  //  背景图片
            rule: '',  //  活动规则
            startTime: '',  // 开始时间
            endTime: '',   //  结束时间
            loginDialog: false,
            share_desc: '',
            base_pic: '',
            show_guide: false,
            no_activity: Boolean,  //  活动是否开启
            emp_img: require("@/assets/img_location_blank@3x.png"),
            lottery_id: '', //  huodongid
            lottery_id_visit: this.$route.query.lottery_id || '',
            team_id: '',  //  组队id
            team_id_visit: this.$route.query.team_id || '',  //  分享过来的组队id
            isAgain: false,  //  是否重新调用我的队伍接口
            isShareStatus: false,  //  是否是通过分享过来的
            visitName: '',  //  邀请人昵称
            user_num: '',  //  队伍人数
            isShareSource: !this.$route.query.team_id,  //  判断是否是分享过来的
            visitStatus: false,  //  邀请按钮状态
            btnStatus: false,  //  领取按钮状态
            tabkey: 0,  //  tab栏index
            activityTip: ''
          }
        },
        mixins: [toolTip, inputMixins],
        computed: {
          ...mapState({
            userInfo: state => state.userInfo  //  用户信息
          })
        },
        destroyed: function () {
          document.body.removeEventListener("touchmove", this.prevent,{ passive:false });
        },
        mounted() {
          this.checkLogin() 
           this.$nextTick(() => {
              let domRef = this.$refs.lotterRef
              this.ios()
            })
        },
        methods: {
          ...mapMutations(["getUser", "getSessionId"]),
          prevent(evt) {
            if (!evt._isScroller) {
                evt.preventDefault();
            }
          },

          ios() {
            document.body.addEventListener("touchmove", this.prevent,{ passive:false });
            this.$nextTick(() => {
              let domRef = this.$refs.lotterRef
              this.overscroll(domRef)             
            })
          },
           overscroll(el) {
             console.log({el})
            el.addEventListener("touchstart", function() {
                let top = el.scrollTop,
                    totalScroll = el.scrollHeight,
                    currentScroll = top + el.offsetHeight;
                if (top === 0) {
                    el.scrollTop = 1;
                } else if (currentScroll === totalScroll) {
                    el.scrollTop = top - 1;
                }
            });
            el.addEventListener("touchmove", function(evt) {
                if (el.offsetHeight < el.scrollHeight)   //如果当前盒子的可视高度  <  实际高度,则开启触摸滑动事件
                    evt._isScroller = true;
            });
          },


          SectionToChinese(section){
            let chnNumChar = ["零","一","二","三","四","五","六","七","八","九"];
            let chnUnitChar = ["","十","百","千","万","亿","万亿","亿亿"];
            let strIns = '', chnStr = '';
            let unitPos = 0;
            let zero = true;
            while(section > 0){
              let v = section % 10;
              if(v === 0){
                if(!zero){
                  zero = true;
                  chnStr = chnNumChar[v] + chnStr;
                }
              }else{
                zero = false;
                strIns = chnNumChar[v];
                strIns += chnUnitChar[unitPos];
                chnStr = strIns + chnStr;
              }
              unitPos++;
              section = Math.floor(section / 10);
            }
            return chnStr;
          },


          //  判断是否是通过分享进来页面的
          isShare() {
            if (!this.isShareSource) {
              this.isShareStatus = true
              let visitName = decodeURIComponent(this.$route.query.nick_name)
              this.visitName = visitName.slice(0, 1) + '****'
              this.getTeamMember()
              console.log('sharename', this.visitName)
            }
          },
          checkLogin() {
            if (!this.userInfo.business_id) {
              this.loginDialog = true
            } else {
              this.getActivityDetail()
            }
          },
          // 弹窗消失
          appear() {
            this.loginDialog = false
          },

           // 弹窗消失
          appearVisit() {
            this.isShareStatus = false
            this.isShareSource = true
            this.$router.push({
              query: {
                team_id: '',
                lottery_id: ''
              }
            })
            this.shareFriend()
          },

          // 点击登录按钮
          submit: debounce( async function (value) {
            let password = value[1].replace(/\s+/g, "")
            let account = value[0]
            if (!account) {
              this.show_layer("cancel", "请输入客户号");
              this.is_request = false
              return;
            }

            if (!password) {
              this.show_layer("cancel", "请输入密码");
              this.is_request = false
              return;
            }
            try {
              let res = null;
              res = await login3({ username: this.seaName, password: md5(password) });
              this.is_request = false;
              if (res.code === 0) {
                this.getActivityDetail()
                this.isShare()
                const {PHPSESSID, user} = res.data;
                const show = res.data.is_show
                if (show) {
                  sessionStorage.setItem('show', show)
                } else {
                  sessionStorage.setItem('show', '')
                }
                SID = PHPSESSID;
                this.getSessionId(PHPSESSID);
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 1500
                });
                this.$store.dispatch("getUserInfo");
                await this.getUser(user);
                await this.getThemeColor();
                try {
                  const data = await this.$store.dispatch('getBusinessRate');
                  if (!data.code) {
                    this.loginDialog = false
                  }
                } catch (e) {
                  
                }
              } else  {
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 1500
                });
              }
            } catch (error) {
              console.warn("Something bad happened in api login: ", error);
            }
          }, 300),

          cancel() {
            this.loginDialog = false
          },

          // 获取主题色
          async getThemeColor() {
            try {
              const res = await getColor(this.$store.state.userInfo.business_id);
              this.img_request = true
              if (res.code === 0) {
                const {color, login_img,login_text} = res.data;
            
                this.login_img = login_img;
                this.login_text = login_text;
            this.txtColor = color;
                document.body.style.setProperty("--main-color", color);
                this.$forceUpdate();
              }
            } catch (error) {
              console.warn("Something bad happened in api getColor: ", error);
            }
          },

          //分享
          shareFriend() {
            let self = this;
            let bid = window.localStorage.getItem("bid");
            let auto_url = encodeURIComponent('ceremony');
            let nick_name = encodeURIComponent(self.userInfo.nickname)
            let link = encodeURI(
              `${encodeURI(baseUrl)}?auto_url=${auto_url}&bid=${encodeURI(
                bid + "#"
              )}/ceremony?team_id=${self.team_id}&lottery_id=${self.lottery_id}&nick_name=${nick_name}`
            );
            wx.config({
              debug: false,
              appId: appId,
              timestamp: timestamp,
              nonceStr: nonceStr,
              signature: signature,
              jsApiList: [
                "checkJsApi",
                // 'onMenuShareTimeline',//旧的接口，即将废弃
                // 'onMenuShareAppMessage',//旧的接口，即将废弃
                "updateAppMessageShareData",
                "updateTimelineShareData",
                "onMenuShareQQ",
                "onMenuShareWeibo",
                "chooseImage",
                "uploadImage",
                "previewImage",
                "openLocation",
                "chooseWXPay"
              ]
            });
            wx.ready(function() {
              let shareData = {
                title: '牛年抽好礼，行好运！', // 分享标题
                desc: '光大银行海口分行代发工资客户专属活动！', // 分享描述
                link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl: 'https://yiqitong.oss-cn-hangzhou.aliyuncs.com/media/image/20201228/activity-hys-share.png' // 分享图标
              };
              wx.onMenuShareAppMessage(shareData);
              wx.onMenuShareTimeline(shareData);
              wx.updateAppMessageShareData(shareData);
              wx.updateTimelineShareData(shareData);
              wx.onMenuShareQQ(shareData);
              wx.onMenuShareWeibo(shareData);
            });
          },

          shareGuide() {
            // this.datailList.forEach((item, index) => {
            //   if (item.team_user) {
            //     this.show_guide = true
            //   } else {
            //     this.$dialog.toast({
            //       mes: '请先加入一个队伍',
            //       timeout: 1500
            //     });
            //   }
            // })
            if (this.visitStatus) {
              this.show_guide = true
            } else {
               this.$dialog.toast({
                  mes: '请先加入一个队伍',
                  timeout: 1500
                });
            }
          },

          joinTeam(id, lottery_id) {
            this.$dialog.confirm({
              title: "",
              mes: '确定参加此队伍吗?',
              opts: () => {
                this.createTeam(id, lottery_id)
                // this.$refs.tab.joinToTeam()
              }
            });
          },

          //  获取活动列表
          async getActivityDetail() {
            this.isShare()
            try {
              let params = {
                type: 3
              }
              const res = await getActivityDetail(params)             
              if (res.code === 0) {
                this.no_activity = false
                this.backImg = res.data.backdrop_img
                this.rule = res.data.rule.replace(/(\r\n|\n|\r)/gm, "<br />") || ''
                this.startTime = res.data.start_time
                this.endTime = res.data.end_time
                this.datailList = res.data.item_list
                this.lottery_id = res.data.id
                this.getMyTeamInfo()
                this.datailList.forEach((item, index) => {
                  if (item.team_user) {
                    this.team_id = item.team_user.team_id
                    this.btnStatus = true
                  }
                })
                for (let i = 0;i < this.datailList.length;i++) {
                  if (this.datailList[i].team_user && !Number(this.datailList[i].team_user.is_grant)) {
                    this.visitStatus = true
                    break
                  }
                }
              }
              if (res.code === -1) {
                this.no_activity = true
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 1500
                });
              }
              this.shareFriend()  //  分享放这里是为了获取teamid
              this.activityTip = res.msg
            } catch (e) {
              // this.$dialog.toast({
              //   mes: '网络繁忙，稍后再试',
              //   icon: 'error',
              //   timeout: 1500
              // });
            }
          },

          //  参加组队
          async createTeam(id, lottery_id) {
            try {
              let params = {
                lottery_item_id: id,
                lottery_id: lottery_id
              }
              const res = await createTeam(params)
              if (res.code === 0) {
                this.isAgain = true
                this.$refs.tab.joinToTeam()
                this.getActivityDetail()
              } else {
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 1500
                });
              }
            } catch(e) {}
          },

          async getTeamMember() {
            try {
              let params = {
                lottery_id: this.lottery_id_visit,
                team_id: this.team_id_visit
              }
              const res = await getTeamMember(params)
              if (res.code === 0) {
                this.isAgain = false
                this.vPeopleList = res.data.user_list
                this.vPeopleList.forEach((item, index) => {
                  item.nickname = item.nickname.slice(0, 1) + '******'
                })
                console.log('我是分享过来的获取队员列表vPeopleList', this.vPeopleList)
              } else {
                this.isShareStatus = false
                this.$router.push({
                  query: {
                    team_id: '',
                    lottery_id: ''
                  }
                })
                this.shareFriend()
                // this.$dialog.toast({
                //   mes: res.msg,
                //   timeout: 1500
                // });
              }
            } catch(e) {}
          },

          async getMyTeamInfo() {
            try {
              let params =  {
                lottery_id: this.lottery_id,
              }
              const res = await getMyTeamInfo(params)
              if (res.code === 0) {
                this.isAgain = false
                this.peopleList = res.data.user_list
                this.user_num = res.data.user_num
                this.peopleList.forEach((item, index) => {
                  item.nickname = item.nickname.slice(0, 1) + '******'
                })
              } else {
                // this.$dialog.toast({
                //   mes: res.msg,
                //   timeout: 1500
                // });
              }
            } catch(e) {}
          },

          //  tab回调
          tabCallBack(label, tabkey) {
            console.log({tabkey})
            this.tabkey = tabkey
            this.$nextTick(() => {
              let domRef = this.$refs.lotterRef
              let teamRef = this.$refs.teamRef
              let ruleRef = this.$refs.ruleRef
              switch(this.tabkey) {
                case 0: 
                  this.overscroll(domRef)
                  break
                case 1: 
                  this.overscroll(teamRef)
                  break
                case 2: 
                  this.overscroll(ruleRef)
                  break
              }
            })
          },

          //  领取奖品
          receiveAwardTeam(team_user_id) {
            this.$router.push({
              name: 'receive_award',
              query: {
                item_id: team_user_id,
                type: 3
              }
            });
          },

          //  不参加
          noJoin() {
            console.log(1)
          },

          exitsTeamTip() {
            this.$dialog.confirm({
              title: "",
              mes: '退出队伍后，您可以选择重新创建队伍或加入其他人的队伍(仅能通过被邀请加入)。请谨慎考虑',
              // opts: () => {
              //   this.exitsTeam()
              // }
              opts: [
                {
                  txt: '取消',
                  color: false,
                  callback: () => {}
                },
                {
                  txt: '狠心退出',
                  color: '#F6432F',
                  callback: () => {
                    this.exitsTeam()                   
                  }
                }
              ]
            });
          },

          async exitsTeam() {
            this.visitStatus = false
            try {
              let params = {
                team_id:this.team_id
              }
              const res = await exitsTeam(params)
              if (res.code === 0) {
                this.peopleList = []
                this.btnStatus = false
                this.getActivityDetail()
              }
              this.$dialog.toast({
                mes: res.msg,
                timeout: 1500
              });
            } catch (e) {}
          },

          async enterTeam() {
            this.isShareSource = true
            try{
              let params = {
                team_id: this.$route.query.team_id || ''
              }
              const res = await enterTeam(params)
              this.$router.push({
                query: {
                  team_id: '',
                  lottery_id: ''
                }
              })
              this.shareFriend()
              if (res.code=== 0) {
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 1500
                });
                this.getActivityDetail()
              } else {
                this.$dialog.toast({
                  mes: res.msg,
                  timeout: 1500
                });
              }
              this.isShareStatus = false
            } catch (e) {}
          }
        }
    }
</script>

<style lang="less" scoped>
@import "../../../../common/less/variable.less";
@import "../../../../common/less/mixin.less";
.team-activity {
    width: 100%;
    height: 100%;
    position: relative;

    .activity-tip {
      .wh(100%, 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #999;
    }
    
  .home {
    position: fixed;
    top: 0.2rem;
    right: 0.2rem;
  }

  .ceremony {
    width: 100%;
    height: 100%;
    .sc(0.28rem, #333);
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.02rem;

    .process {
      .wh(calc(100% - 0.2rem), 7.4rem);
      padding-top: 1rem;

      .process-txt {
        padding: 0 0.6rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #F6432F;
        border-radius: 0.16rem;

        .process-one {
          width: 1.2rem;
          // .wh(1.2rem, 1.6rem);
          .sc(0.28rem, #fff);
          text-align: center;

          .img {
            width: 0.8rem;
            height: 0.8rem;
            margin-bottom: 0.08rem;
          }
        }
      }

      .ceremony-content {
        border-radius: 0.16rem;
        overflow: hidden;

        .yd-tab {
          background: #fff;

          /deep/ .yd-tab-active {
            color: #F6432F !important;
            background: #fff;
          }

          /deep/ .yd-tab-active::before {
            display:none;
          }
          
          .yd-tab-panel-item {
            height: 6rem;

            .team-border {
              margin:  0.2rem 0;
              height: 5.5rem;
              overflow: auto;
              -webkit-overflow-scrolling: touch;
            }

            .team-prize {
              margin: 0.3rem 0.2rem 0 0.4rem;
              height: 1.64rem;
              background: url('~@/assets/haiyuansu/prize_bg.png');
              position: relative;
              background-size: 100% 100%;
              display: flex;
              align-items: center;

              .img {
                margin-left: 0.36rem;
                .wh(1.44rem, 1.44rem);
                
                img {
                  .wh(1.44rem, 1.44rem);
                  display: block;
                }
              }

              .claim {
                .sc(0.28rem, #333);
                .wh(2.48rem, 1.44rem);
                margin-left: 0.7rem;
                position: relative;

                .clain-con {
                  font-size: 0.24rem;
                  margin-top: 0.2rem;

                  .number {
                    color: #F6432F;
                    font-size: 0.32rem;
                    font-weight: bolder;
                  }
                }

                .team-user {
                  .sc(0.24rem, #F6432F);
                  position: absolute;
                  bottom: 0;

                  .schedule {
                    .wh(2.3rem, 0.06rem);
                    background: #ccc;
                    margin-top: 0.06rem;
                    border-radius: 10%;

                    .actually-sch {
                      height: 100%;
                      background: #F6432F;
                    }
                  }
                }

                .prize-name {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }

              .btn {
                
                .btn-layout {
                  .wh(1.44rem, 0.6rem);
                  .sc(0.24rem, #fff);
                  border-radius: 0.64rem;
                  background: #F6432F;
                  line-height: 0.6rem;
                  text-align: center
                }

               .disabled {
                 background: #ccc;
               }

                .failure {
                  background: #ccc;
                }
              }

              .end {
                height: 100%;
                width: 1.6rem;
                text-align: right;

                .team-end {
                  margin-top: 0.1rem;
                  img {
                    .wh(0.8rem, 0.8rem);
                  }

                  .detail-team {
                    .sc(0.24rem, #ccc);
                    text-decoration:underline;
                    margin-top: 0.3rem;
                  }
                }
              }

              .border-icon {
                .wh(1.4rem, 0.74rem);
                .sc(0.28rem, #fff);
                position: absolute;
                top: 0;
                left: -0.18rem;
                background: url('~@/assets/haiyuansu/team_icon.png');
                background-size: 100% 100%;
                text-align: center;
                line-height: 0.6rem;

                img {
                  .wh(1.2rem, 0.74rem);
                }
              }
            }

            .my-team {
              .wh(calc(100% - 0.2rem), 0.6rem);
              position: relative;
              left: 0.1rem;

              .eqit {
                .sc(0.24rem, #999);
                position: absolute;
                right: 0;
                bottom: 0;
              }

              .team-num {
                width: 4rem;
                .wh(4rem, 0.3rem);
                position: absolute;
                text-align: center;
                left: 50%;
                top: 50%;
                margin: -0.15rem 0 0 -2rem;
                .sc(0.28rem, #F6432F);
                line-height: 0.6rem;
              }
            }

            .no-team {
              .wh(100%, 5.72rem);
              line-height: 5.72rem;
              text-align: center;
              color: #999;
            }

            .detail {
              margin: 0.2rem;
              height: 4.5rem;
              border: 0.02rem dashed #f6432f;
              overflow: auto;
              border-radius: 0.08rem;

              .detail-title {
                margin-top: 0.2rem;
                overflow: hidden;

                .common {
                  // width: 50%;
                  float: left;
                  text-align: center;
                  .sc(0.28rem, #333);
                }
                
                .mobile,.time {
                  color: #999;
                }

                .mobile, .kehu-num {
                  width: 40%;
                }

                .time, .join-time {
                  width: 60%;
                }
              }
            }

            .active-rule {
              margin: 0 0.2rem;

              .active-time {

                p {
                  margin-top: 0.3rem;
                }

                .time-detail {
                  color: #333;
                  overflow: auto;
                }
                
                .time-num {
                  color: #333;
                }

                .time-txt {
                }

                .rule-detail {
                  .wh(100%, 3.5rem);
                  border: 0.02rem dashed #f6432f;
                  margin-top: 0.2rem;
                  padding: 0.2rem;
                  border-radius: 0.08rem;
                  
                  .rule-txt {
                    margin: 0;
                    overflow:hidden;
                    letter-spacing: 0.02rem;
                    line-height: 0.4rem;
                    color: #999;
                    word-break: normal;
                  }
                }
              }

            }
          }
        }
      }
    }

    .visited-btn {
      .wh(100%, 0.86rem);
      .sc(0.28rem, #fff);
      text-align: center;
      line-height: 0.86rem;
      background: #F6432F;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: center;

      .btn-con {
        display: flex;
        align-items: center;

        img {
        .wh(0.44rem, 0.44rem);
        margin-right: 0.2rem;
      }
      }
    }

    .no-click {
      background: #ccc;
    }

    .share-user {
      .wh(100vw, 100vh);
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 0;
      left: 0;

      .visit-user {
        .wh(6rem, 8rem);
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -4.5rem 0 0 -3rem;
        background: #fff;
        border-radius: 0.1rem;

        .visit-content {
          margin: 0.2rem;
          text-align: center;

          .visit-title {
            line-height: 0.5rem;
          }

          .number-list {
            width: 100%;
            height: 4rem;
            border: 0.02rem dashed #f6432f;
            border-radius: 0.2rem;
            overflow: auto;

            .detail-title {
              margin-top: 0.2rem;
              overflow: hidden;

              .common {
                // width: 50%;
                float: left;
                text-align: center;
                .sc(0.28rem, #333);
              }
              
              .mobile,.time {
                color: #999;
              }

              .mobile, .kehu-num {
                width: 40%;
              }

              .time, .join-time {
                width: 60%;
              }
            }
          }

          .join-team {
            margin: 0.2rem 0.2rem 0 0.2rem;
            background: #F6432F;
            height: 1rem;
            text-align: center;
            border-radius: 0.1rem;
            line-height: 1rem;
            color: #fff;
          }
          .no-join {
            margin: 0 0.2rem 0 0;
            height: 1rem;
            line-height: 1rem;
            text-align: center;
            border-radius: 0.1rem;
            color: #999;
          }
        }
      }
    }

    /*引导分享*/

    .guide-bg {
      position: fixed;
      .wh(100%, 100%);
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      z-index: 1;
    }

    .guide-box {
      position: fixed;
      .wh(100%, 100%);
      top: 0;
      left: 0;
      z-index: 2;

      .guide-top {
        .wh(4.5rem, 3.7rem);
        background: url("~@/assets/img_share@3x.png") no-repeat;
        background-size: 100%;
        margin-left: 2.58rem;
        margin-bottom: 2.58rem;
      }

      .guide-btn {
        .wh(2.6rem, 0.9rem);
      }
    }
  }

  // .lucky-wheel {
  //   height: 100vh;
  //    .empty-box {
  //       background: @color-white;
  //       height: 100vh;
  //       width: 100vw;

  //       img {
  //         .wh(3.64rem, 2.58rem);
  //         margin-top: 1.76rem;
  //         margin-bottom: 0.74rem;
  //       }

  //       .sc(0.28rem, @color-light-grey);
  //     }

  //     .login-entrys {
  //       .wh(100vw, 100vh);
  //       background: rgba(0,0,0, 0.4);
  //       position: absolute;
  //     }
  //   }
    .login-entrys {
      .wh(100vw, 100vh);
      background: rgba(0,0,0, 0.4);
      top: 0;
      left: 0;
      position: absolute;
    }
}
</style>
