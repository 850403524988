var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "team-activity" },
    [
      _c(
        "router-link",
        { staticClass: "home", attrs: { to: "seaIndex" } },
        [
          _c("yd-icon", {
            attrs: { name: "home", size: ".5rem", color: "#fff" },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "ceremony",
          style: {
            backgroundImage:
              "url(" +
              (_vm.backImg
                ? _vm.backImg
                : require("@/assets/haiyuansu/teamBack.jpg")) +
              ")",
          },
        },
        [
          _c("div", { staticClass: "process" }, [
            _c(
              "div",
              { staticClass: "ceremony-content" },
              [
                _c(
                  "tab",
                  {
                    ref: "tab",
                    attrs: {
                      callback: _vm.tabCallBack,
                      "border-color": "#fff",
                      "bg-color": "#F6432F",
                      color: "#fff",
                    },
                  },
                  [
                    _c(
                      "yd-tab-panel",
                      { attrs: { tabkey: "0", label: "组队奖励" } },
                      [
                        _c(
                          "div",
                          { ref: "lotterRef", staticClass: "team-border" },
                          [
                            _vm._l(_vm.datailList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        !_vm.no_activity ||
                                        _vm.datailList.length >= 1,
                                      expression:
                                        "!no_activity || datailList.length >= 1",
                                    },
                                  ],
                                  key: item.id,
                                  staticClass: "team-prize",
                                },
                                [
                                  _c("div", { staticClass: "border-icon" }, [
                                    _vm._v(
                                      "\n                    礼包" +
                                        _vm._s(index + 1) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "img" }, [
                                    _c("img", {
                                      attrs: { src: item.item_img, alt: "" },
                                    }),
                                  ]),
                                  _c("div", { staticClass: "claim" }, [
                                    _c("p", { staticClass: "prize-name" }, [
                                      _vm._v(_vm._s(item.item_name)),
                                    ]),
                                    _c("p", { staticClass: "clain-con" }, [
                                      _vm._v("队伍累计需要"),
                                      _c("span", { staticClass: "number" }, [
                                        _vm._v(_vm._s(item.team_goal_num)),
                                      ]),
                                      _vm._v("人"),
                                    ]),
                                    item.team_user
                                      ? _c(
                                          "div",
                                          { staticClass: "team-user" },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  item.team_user.user_num
                                                ) +
                                                  "/" +
                                                  _vm._s(item.team_goal_num)
                                              ),
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "schedule" },
                                              [
                                                _c("div", {
                                                  staticClass: "actually-sch",
                                                  style: {
                                                    width:
                                                      (item.team_user.user_num /
                                                        item.team_goal_num) *
                                                        100 +
                                                      "%",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "btn",
                                      class: {
                                        end:
                                          item.team_user &&
                                          item.team_user.is_grant,
                                      },
                                    },
                                    [
                                      item.team_user && !item.team_user.is_grant
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "btn-layout",
                                              class: {
                                                failure:
                                                  !item.team_user.is_enough,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  item.team_user.is_enough
                                                    ? _vm.receiveAwardTeam(
                                                        item.team_user.id
                                                      )
                                                    : ""
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  item.team_user.is_enough
                                                    ? "领取奖励"
                                                    : "未完成"
                                                )
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      item.team_user && item.team_user.is_grant
                                        ? _c(
                                            "div",
                                            { staticClass: "team-end" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: require("@/assets/haiyuansu/already.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "detail-team",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.receiveAwardTeam(
                                                        item.team_user.id
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("查看领取详情")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      !item.team_user
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "btn-layout",
                                              class: {
                                                disabled:
                                                  _vm.visitStatus ||
                                                  Number(item.item_num) <= 0 ||
                                                  _vm.btnStatus,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  _vm.visitStatus ||
                                                  Number(item.item_num) <= 0 ||
                                                  _vm.btnStatus
                                                    ? ""
                                                    : _vm.joinTeam(
                                                        item.id,
                                                        item.lottery_id
                                                      )
                                                },
                                              },
                                            },
                                            [_vm._v("参与组队")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.no_activity ||
                                      _vm.datailList.length < 1,
                                    expression:
                                      "no_activity || datailList.length < 1",
                                  },
                                ],
                                staticClass: "activity-tip",
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.no_activity
                                        ? _vm.activityTip
                                        : "暂无礼包"
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "yd-tab-panel",
                      { attrs: { tabkey: "1", label: "我的队伍" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.peopleList.length >= 1,
                                expression: "peopleList.length >= 1",
                              },
                            ],
                            staticClass: "team-border",
                          },
                          [
                            _c("div", { staticClass: "my-team" }, [
                              _c("p", { staticClass: "team-num" }, [
                                _vm._v(
                                  "您的队伍已有" + _vm._s(_vm.user_num) + "人"
                                ),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass: "eqit",
                                  on: { click: _vm.exitsTeamTip },
                                },
                                [_vm._v("退出队伍")]
                              ),
                            ]),
                            _c(
                              "div",
                              { ref: "teamRef", staticClass: "detail" },
                              [
                                _c("div", { staticClass: "detail-title" }, [
                                  _c("p", { staticClass: "kehu-num common" }, [
                                    _vm._v("客户名"),
                                  ]),
                                  _c("p", { staticClass: "join-time common" }, [
                                    _vm._v("加入时间"),
                                  ]),
                                ]),
                                _vm._l(_vm.peopleList, function (item, ind) {
                                  return _c(
                                    "div",
                                    { key: ind, staticClass: "detail-title" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "mobile common" },
                                        [_vm._v(_vm._s(item.nickname))]
                                      ),
                                      _c("p", { staticClass: "time common" }, [
                                        _vm._v(_vm._s(item.create_time)),
                                      ]),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.peopleList.length < 1,
                                expression: "peopleList.length < 1",
                              },
                            ],
                            staticClass: "no-team",
                          },
                          [_vm._v("\n              暂无队伍信息\n            ")]
                        ),
                      ]
                    ),
                    _c(
                      "yd-tab-panel",
                      { attrs: { tabkey: "2", label: "活动规则" } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.no_activity,
                                expression: "!no_activity",
                              },
                            ],
                            staticClass: "active-rule team-border",
                          },
                          [
                            _c("div", { staticClass: "active-time" }, [
                              _c("p", { staticClass: "time-txt" }, [
                                _vm._v("活动时间"),
                              ]),
                              _c("p", { staticClass: "time-num" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("formatDateTime")(_vm.startTime)
                                  ) +
                                    " 至 " +
                                    _vm._s(
                                      _vm._f("formatDateTime")(_vm.endTime)
                                    )
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "active-time" }, [
                              _c("p", { staticClass: "time-txt" }, [
                                _vm._v("活动规则"),
                              ]),
                              _c(
                                "div",
                                {
                                  ref: "ruleRef",
                                  staticClass: "rule-detail time-detail",
                                },
                                [
                                  _c("p", {
                                    staticClass: "rule-txt",
                                    domProps: { innerHTML: _vm._s(_vm.rule) },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.no_activity,
                                expression: "no_activity",
                              },
                            ],
                            staticClass: "activity-tip",
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.activityTip) +
                                "\n            "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "visited-btn",
              class: { "no-click": !_vm.visitStatus },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.shareGuide.apply(null, arguments)
                },
              },
            },
            [_vm._m(0)]
          ),
          _c("alert-tip", {
            attrs: {
              type: _vm.type,
              "show-alert": _vm.show_tip,
              "alert-text": _vm.tip,
            },
            on: { changeShowAlert: _vm.changeShow },
          }),
          _vm.isShareStatus
            ? _c(
                "div",
                {
                  staticClass: "share-user",
                  on: {
                    click: function ($event) {
                      if ($event.target !== $event.currentTarget) return null
                      return _vm.appearVisit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "visit-user" }, [
                    _c("div", { staticClass: "visit-content" }, [
                      _c("p", { staticClass: "visit-title" }, [
                        _vm._v("组队邀请"),
                      ]),
                      _c("p", { staticClass: "visit-title" }, [
                        _vm._v(
                          _vm._s(_vm.visitName) +
                            "邀请您一起参与组队，赢取豪礼！"
                        ),
                      ]),
                      _c("p", { staticClass: "visit-title" }, [
                        _vm._v("队伍成员"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "number-list" },
                        [
                          _vm._m(1),
                          _vm._l(_vm.vPeopleList, function (item, ind) {
                            return _c(
                              "div",
                              { key: ind, staticClass: "detail-title" },
                              [
                                _c("p", { staticClass: "mobile common" }, [
                                  _vm._v(_vm._s(item.nickname)),
                                ]),
                                _c("p", { staticClass: "time common" }, [
                                  _vm._v(_vm._s(item.create_time)),
                                ]),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "join-team",
                          on: { click: _vm.enterTeam },
                        },
                        [_vm._v("\n            马上进组赢好礼\n          ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "no-join",
                          on: { click: _vm.appearVisit },
                        },
                        [_vm._v("\n            我再想想\n          ")]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.show_guide
            ? _c("div", {
                staticClass: "guide-bg",
                on: {
                  click: function ($event) {
                    _vm.show_guide = false
                  },
                },
              })
            : _vm._e(),
          _vm.show_guide
            ? _c("div", { staticClass: "guide-box" }, [
                _c("div", { staticClass: "guide-top" }),
                _c(
                  "div",
                  {
                    staticClass: "flex-center",
                    on: {
                      click: function ($event) {
                        _vm.show_guide = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "guide-btn",
                      attrs: {
                        src: require("@/assets/button_iknow@3x.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.loginDialog
        ? _c(
            "div",
            {
              staticClass: "login-entrys",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) return null
                  return _vm.appear.apply(null, arguments)
                },
              },
            },
            [
              _c("login-dialog", {
                on: {
                  submit: function ($event) {
                    return _vm.submit(arguments)
                  },
                  cancel: _vm.cancel,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "btn-con" }, [
      _c("img", {
        attrs: { src: require("@/assets/haiyuansu/forward.png"), alt: "" },
      }),
      _c("span", [_vm._v("立即邀请")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "detail-title" }, [
      _c("p", { staticClass: "kehu-num common" }, [_vm._v("客户名")]),
      _c("p", { staticClass: "join-time common" }, [_vm._v("加入时间")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }