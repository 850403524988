var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "yd-tab" }, [
    _c(
      "div",
      {
        staticClass: "yd-tab-box",
        class: _vm.horizontalScroll ? "yd-tab-nav-scoll" : "yd-tab-nav-nomal",
        style: { backgroundColor: _vm.bgColor },
      },
      [
        _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.navList.length > 0,
                expression: "navList.length > 0",
              },
            ],
            ref: "navbox",
            staticClass: "yd-tab-nav",
            style: {
              color: _vm.borderColor,
              width: _vm.width,
              fontSize: _vm.fontSize,
            },
          },
          _vm._l(_vm.navList, function (item, key) {
            return _c(
              "li",
              {
                key: key,
                staticClass: "yd-tab-nav-item",
                class:
                  item._uid == _vm.activeIndex || key === _vm.activeIndex
                    ? "yd-tab-active"
                    : "",
                style: {
                  color:
                    item._uid == _vm.activeIndex || key === _vm.activeIndex
                      ? _vm.activeColor
                      : _vm.color,
                  lineHeight: _vm.height,
                },
                on: {
                  click: function ($event) {
                    return _vm.changeHandler(
                      item.label,
                      key,
                      item._uid,
                      item.tabkey
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(item.label) +
                    "\n                "
                ),
                item.badge
                  ? _c(
                      "span",
                      { staticClass: "yd-tab-badge" },
                      [
                        _c(
                          "yd-badge",
                          {
                            attrs: {
                              scale: item.badgeScale,
                              color: item.badgeColor,
                              bgcolor: item.badgeBgcolor,
                            },
                          },
                          [_vm._v(_vm._s(item.badge))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                item.dot && !item.badge
                  ? _c("span", { staticClass: "yd-tab-dot" }, [
                      _c("i", { style: { backgroundColor: item.dotColor } }),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]
    ),
    _c("div", { staticClass: "yd-tab-panel" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }